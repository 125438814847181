<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :isInline="true"
      :editTitle="'巡检记录'"
    >
    </app-list>

  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/meritPatrolLog",
      searchVal: "",
      form: { floor: 1, mapUrl: "" },
      searchQuery: { trainName: "" },

      searchControl: {
        firstSoltSpan: [2, 2, 2, 2],
        secondSoltSpan: [2, 2, 2, 2],
        thirdSoltSpan: [18, 18, 18, 18],
      },

      dialogVisible: false,
      props: [
        {
          label: "巡检人",
          prop: "name",
          align: "center",
        },

        {
          label: "巡检内容",
          prop: "patrolContent",
          align: "center",
        },

        {
          label: "巡检日期",
          prop: "patrolDate",
          align: "center",
        },
        {
          label: "巡检位置",
          prop: "locationName",
          align: "center",
        },

        {
          label: "状态",
          prop: "status",
          align: "center",
          type: "colorText",
          formatter: function(row) {
            const statusMap = {
              0: { color: "#909399", text: "正常" },
              1: { color: "#F56C6C", text: "未处理" },
              2: { color: "#67C23A", text: "已处理" },
            };
            return statusMap[row.status];
          },
        },

        {
          label: "创建时间",
          prop: "patrolDate",
          align: "center",
        },
      ],

      formProps: [
        {
          label: "巡检人",
          prop: "meritPatrolPersonId",
          type: "select",
          selectData: [],
        },

        {
          label: "巡检位置",
          prop: "meritLocationManageId",
          type: "select",
        },
        {
          label: "状态",
          prop: "status",
          type: "select",
          selectData: [
            { label: "正常", value: 0 },
            { label: "未处理", value: 1 },
            { label: "已处理", value: 2 },
          ],
        },
        {
          label: "巡检日期",
          prop: "patrolDate",
          type: "date",
        },
        {
          label: "巡检内容",
          prop: "patrolContent",
          type: "textarea",
          isFull: true,
        },

        {
          label: "整改前图片",
          prop: "patrolDate",
          type: "upload",
        },
        {
          label: "整改后图片",
          prop: "patrolDate",
          type: "upload",
        },
      ],
    };
  },
  created() {
    this.getPersonList();
  },
  methods: {
    async getPersonList() {
      let res = await api.common.list({ apiName: "/meritPatrolPerson" });
      let array = [];
      res.data.data.forEach((element) => {
        array.push({ label: element.name, value: element.id });
      });
      this.formProps[0].selectData = array;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
